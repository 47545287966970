* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input,
textarea {
  -webkit-user-select: auto;
  /*webkit浏览器*/
  margin: 0px;
  padding: 0px;
  outline: none;
}
html {
  height: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: #f8f8f8;
  -webkit-overflow-scrolling: touch;
}
.am-navbar {
  height: 50px !important;
}
.am-navbar-left {
  padding-left: 5px !important;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .am-action-sheet-content .am-action-sheet-share-cancel-button {
    padding-bottom: 30px;
    height: 80px;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .am-action-sheet-content .am-action-sheet-share-cancel-button {
    padding-bottom: 30px;
    height: 80px;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .am-action-sheet-content .am-action-sheet-share-cancel-button {
    padding-bottom: 30px;
    height: 80px;
  }
}

